/*
Version: 1.4
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
Not Found page styles
*/

@use '../abstracts/' as *;

// Full-screen into block background
.notfound-page-container {
  background-image: url("../../assets/404-notfound.jpg");
}

    