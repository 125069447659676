/*
Version: 1.3
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
Base page styles
*/

@use '../abstracts/' as *;

// Footer navigation
.footer-nav {
  width: 100%;
  background-color: $darkColor;
  padding: 50px 0;
  // Footer menu link style
  .nav-link {
      color: $whiteColor;
      text-transform: uppercase;
  }
  // Logo style for the footer
  .logo {
    img {
        width: 200px;
    }
  }
}

// Copyright text style
.footer-text {
  font-size: 12px;
  text-align: center;
  color: $whiteColor;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px dotted $whiteColor;
}
