/*
Version: 1.3
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
Variables file of the project, contains all SCSS variables used in the project
*/

// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

$primaryFont: 'Lato', Arial, Helvetica, sans-serif; // Site font

$primaryColor: #718EC4; // Primary brand color
$complementaryColor: #DC8998; // Complimentary brand color
$darkColor: #231237; // Dark brand color
$whiteColor: #FFFFFF; // White color
$greyColor: #676767; // Middle grey color
$lightGreyColor: #e4e4e4; // Light grey color
$successColor: #16a34a; // Success color
$errorColor: #dc2626; // Error color
$textColor: #1E1E1E; // Text color
$panelColor: #CCCCCC; // Panel color
$panelBackgroundColor: #F5F5F5; // Panel background color
$tagColor: #999999; // Tag color

$spacer: 2rem; // Spacing between elements