/*
Version: 1.4
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
Home page styles
*/

@use '../abstracts/' as *;

// Full-screen into block background
.home-page-container {
  background-image: url("../../assets/home_background.jpg");
}

// Features block styles
.feature-list {
  // Features item style
  .feature {
    border-top: 0.3px solid $complementaryColor;
    border-left: 0.3px solid $complementaryColor;
    border-radius: 10px;
    box-shadow: 4px 4px 4px transparentize($complementaryColor, 0.8);
    padding: 20px;

    img {
      width: 100%;
      max-width: 60px;
      margin: 0 auto;
      display: block;
      border: 0;

    }

    p {
      font-size: 20px;
      margin: 10px 0 0 0;
      text-align: center;
      font-weight: 300;

    }
  }
}

// Proficiencies block styles
.block-proficiencies {
  background: linear-gradient(180deg, transparentize($complementaryColor, 0.8) 0%, transparentize($primaryColor, 0.8) 60%);
}