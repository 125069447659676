/*
Version: 1.9
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
Projects page styles
*/

@use '../abstracts/' as *;

// Full-screen into block background
.projects-page-container {
  background-image: url("../../assets/projects_background.jpg");
}

// Project card styles
.project-card {
  cursor: pointer;
  width: auto;
  height: auto;
  position: static;
  transition: all 0s;
  transform-origin: center;

  // Close Button, hidden by default
  .close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    color: $greyColor;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    border: 1px solid $greyColor;
  }

  // Card style
  .card {
    font-size: 18px;
    border: 0.3px solid $panelColor;
    border-radius: 10px;
    box-shadow: 4px 4px 4px transparentize($darkColor, 0.95);
    background-color: $panelBackgroundColor;

    // Screenshot image style
    img {
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid $panelColor;

    }

    // Project title style
    .headline {
      font-size: 26px;
      color: $primaryColor;
      font-weight: 300;
      text-transform: uppercase;
    }

    // Project tags style
    .tags {
      margin: 0;
      font-size: 12px;
      span {
        display: inline-block;
        white-space: nowrap;
        margin-right: 10px;
        padding: 2px 10px;
        border: 1px solid $tagColor;
        background: $whiteColor;
        border-radius: 3px;
        color: $tagColor;
        margin: 3px;
      }
    }
  }
}

// Expanded full-screen project card style
.project-card.expanded {
  cursor: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px;
  z-index: 100;
  background-color: transparentize($darkColor, 0.3);
  overflow: auto;
  transition: all 0.3s ease;
  // Show close button
  .close {
    display: block;
  }
  // Card style
  .card {
    width: 100%;
    max-width: 1294px;
    margin: 0 auto;
    // Tags style
    .tags {
      margin-bottom: 5px;
      span {
        margin-right: 10px;
        padding: 2px 5px;
        border-radius: 3px;
      }
    }
    // Project link button style
    .project-link {
      padding: 5px 25px;
      font-size: 18px;
      line-height: 25px;
      color: $whiteColor;
      background-color: $primaryColor;
      border-radius: 5px;
      border: 1px solid $primaryColor;
      text-decoration: none;
    }
  }
}

// Main project tag list control
.tag-list {
  margin: 10px 0 20px 0;
  font-size: 14px;
  // Tag button
  button {
    margin: 5px;
    padding: 2px 10px;
    border: 1px solid $textColor;
    background: $lightGreyColor;
    border-radius: 5px;
    color: $textColor;
    // hover
    &:hover {
      border-color: $primaryColor;
      color: $primaryColor;
    }
    // active
    &.active {
      background: $primaryColor;
      border-color: $primaryColor;
      color: $whiteColor;
    }
  }
}

// Mobile styles
@media (max-width: 768px) {
    // Projects card reduce padding
  .project-card.expanded {
    padding: 20px;
  }
}
