/*
Version: 1.1
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
Base SCSS file of the project, contains all base styles
*/

@use '../abstracts/' as *;

body {
  font-family: $primaryFont;
  background: $whiteColor;
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.apptheme {
  margin-top: 0rem;
  margin-bottom: 0rem;
  flex: 1;
}