/*
Version: 1.6
Last edited by: Natalia Pakhomova
Last edit date: 29/03/2023
About page styles
*/

@use '../abstracts/' as *;

// Full-screen into block background
.about-page-container {
  background-image: url("../../assets/about_background.jpg");
}